"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var icons_vue_1 = require("@ant-design/icons-vue");
var ant_design_vue_1 = require("ant-design-vue");
var vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    name: "CzSpinLoader",
    components: {
        Spin: ant_design_vue_1.Spin,
    },
    setup: function () {
        var indicator = (0, vue_1.h)(icons_vue_1.LoadingOutlined, {
            style: {
                fontSize: "24px",
            },
            spin: true,
        });
        return {
            indicator: indicator,
        };
    },
});
